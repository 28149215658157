
<template>
  <div>
    <div v-permission="'disciplines.create'" class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{$t('AddNew')}}</span>
      </div>
    </div>
    <Grid
      :key="key"
      :service="service"
      route_name="disciplines"
      hide_actions="true"
    >
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="title" sort-key="title">{{ $t('classes_title') }}</vs-th>
        <vs-th key="description" sort-key="description">{{ $t('description') }}</vs-th>
        <vs-th key="course" sort-key="org_course.name">{{ $t('course') }}</vs-th>
        <vs-th key="actions">{{ $t('Actions') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div v-html-safe="tr.id"></div>
          </vs-td>
          <vs-td width="25%" key="title">
            <div v-html-safe="tr.title"></div>
          </vs-td>
          <vs-td width="30%" key="description">{{
            tr.description ? tr.description : ''
          }}</vs-td>
          <vs-td width="25%" key="course">{{
            tr.course ? tr.course.name : ''
          }}</vs-td>
          <vs-td width="15%">
              <feather-icon
                v-permission="'disciplines.edit'"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="props.edit(props.data[indextr].id)"
              />
              <feather-icon
                v-permission="'disciplines.delete'"
                icon="Trash2Icon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                @click="props.destroy(props.data[indextr])"
              />
            </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>


<script>
import DisciplineService from '@/services/api/DisciplineService'

export default {
  data: () => ({
    selected: [],
    service: null,
    key: 0
  }),
  watch: {
    activeInstitution() {
      this.key++
    }
  },
  computed: {
    activeInstitution() {
      return this.$store.getters['institution/active']
    }
  },
  methods: {
    addNew() {
      this.$router.push('/disciplines/create')
    }
  },
  beforeMount() {
    this.service = DisciplineService.build(this.$vs)
  }
}
</script>
